



.document_static{
    font-size: 16px;
}

.ant-statistic-content{
    font-size: 16px;
}

.ant-layout-sider{
    /* max-width   : 245px !important; */
    /* min-width   : 245px !important; */
    /* width       : 230px !important; */
    background : #FFFFFF !important;
}

.ant-menu{
    /* background  : #EE7420 !important; */
    /* color       : #fff; */
    /* width: 200px !important; */
    /*max-width   : 245px !important;
    width       : 245px !important; */
    /* color : #fff; */
}

.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
    /* color       : #fff; */
}

.pepleCard{
    background  : #001529 !important;
    color       : #fff !important;
}

.pepleCard .wcolor{
    color: #fff;
}

.ant-layout-sider-trigger{
    /* width: 230px !important; */
    background : #DD3C21;
}


.personCard{
    border : 1px solid #4B7FFF;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0px;
}

.ant-form-item-label > label {
    font-weight: bold;
}

.ant-page-header {
    border-radius: 6px;
}

.header-search .ant-select-item-group,
.header-search .ant-select-item-option-grouped {
    box-shadow: 0px -1px 0px #f2f2f2, 0px 1px 0px #f2f2f2;
}

.header-search .ant-select-item-option-grouped {
    padding: 10px;
}

.header-search .ant-select-item-group {
    padding: 16px;
}

.header-search .ant-select-item-option-content {
    white-space: unset;
}

#stock-number .ant-input-number-handler-wrap {
    opacity: 1;
}

.ant-layout {
    background: #fff !important;
}

.ant-layout-header {

    line-height: inherit !important;

}

.btn-logout.ant-btn-icon-only  {
    width: 50px;
    height: 40px;
    background: #ecf5fded;
    color: #b5b3b3;
}

.btn-logout.ant-btn {
    border-color:transparent
}

.card_cons{
    padding:  30px;
    background: #4B7FFF;
}


.wrapper-body > .ant-card-body {
    padding-right: 0px;
    padding-left: 0px;
}


.ant-upload-list-picture .ant-upload-list-item-name {
    color: green;
}