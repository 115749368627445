.marker {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #ff0000;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
  
  .marker:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    /* background: #302558; */
    position: absolute;
    border-radius: 50%;
  }
  