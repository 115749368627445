.ant-menu { 
    border-radius: 5px;
    /* background: #f5f5f5; */
}

.ant-menu:not(.ant-menu-horizontal) .menu-active.ant-menu-item-selected {
    background-color: #ee742021;
}
.menu-active.ant-menu-item-selected {
    color: #EE7420
}

.ant-menu-vertical .menu-active.ant-menu-item:after, .ant-menu-vertical-left .menu-active.ant-menu-item:after, .ant-menu-vertical-right .menu-active.ant-menu-item:after, .ant-menu-inline .menu-active.ant-menu-item:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-left: 3px solid #EE7420;
}

/* .ant-menu-item:hover ,  .ant-menu-item:hover .btn-hide{
    color: #1890ff !important;
} */

.hide {
    display: none;
}

.show {
    display:block
}
