.meta-title > .ant-card-meta-detail{
    width: 100%;
}
.card-title {
    display: flex;
    justify-content: space-between;
}
.card-edit { 
    justify-content: end;
    display: flex;
}

.profile-card.ant-card-bordered {
    border:none;
    padding: 0 0 0 1rem;
    height: 100%;
}   

.not-title.ant-card-bordered {
    padding:1rem
}

.avatar-pro.ant-card-bordered {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.meta-title > .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0 !important
}

