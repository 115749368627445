.content_title{
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2%;
}

.content{
    font-size: 1rem;
    font-weight: 700;
}

.wrap-con {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem;
}

h2,h6,p {
    margin-bottom:0
}

.profile-con {
    border-right: 2px dashed;
    border-color: #808080a1;
}

.profile-con.left-con {
    padding: 0 10px 0 0;
}

.profile-con.rigth-con {
    padding: 0 0 0 24px;
    border-right:none
}

.profile-no { 
    font-weight: bold;
    color: #000;
}

.profile-desc > h5 , .profile-desc > .profile-join {
    color: #8e8e8e
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color : transparent
}

.modeal-custom > .ant-modal-content > .ant-modal-header > .ant-modal-title {
    text-align: center;
    font-size: 2em;
}

.modeal-custom > .ant-modal-content > .ant-modal-header {
    border-bottom: none
}

.ant-form-vertical .ant-form-item-label {
    padding: 0;
}

.input {
    border-radius: 3px;
}