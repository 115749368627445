.layout {
    /* background: radial-gradient(50% 50% at 50% 50%, #ff9c22 0%, #ff9d34 100%); */
    background-color: #E65821 !important;
    background-image: url('../../../public/images/bg/bg2.svg') !important;
    background-size: cover !important;
    /* background-position: "center"; */
    background-repeat: no-repeat !important;
  }
  
  .container {
    max-width: 408px;
    margin: auto;
  }
  
  .title {
    text-align: center;
    color: #626262;
    font-size: 30px;
    letter-spacing: -0.04em;
  }
  
  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .logoLogin{
      width: 100px !important;
  }

  .buttoncolor {
    color: #007DB5;
    background-color: #007DB5;
}

  